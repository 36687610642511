<template>
  <div>
    <h4 class="page-title">{{ $t("MY_PROFILE.PROFILE_PAGE") }}</h4>
    <div class="custom-navbar-container">
      <b-tabs class="custom-navbar">
        <b-tab
          v-for="(tab, index) in tabs"
          :key="index"
          :title="tab.name"
          :active="currentRoute === tab.link"
          @click="handleRedirct(tab.link)"
        >
        </b-tab>
      </b-tabs>
      <div class="button-exchange">
        <Button
          @click="handleOpenExchangeModal"
          v-if="user && user.exchanges && !user.exchanges.data.length"
          a-tag="buttonLinkExchange"
        >
          <b-icon icon="link45deg"></b-icon>
          {{ $t("MY_PROFILE.LINK_EXCHANGE") }}
        </Button>
      </div>
    </div>
    <router-view />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      loading: false,
    };
  },

  computed: {
    ...mapGetters(["user", "token"]),
    tabs() {
      const profileTabs = [
        {
          name: this.$t("MY_PROFILE.ACCOUNT_MANAGEMENT"),
          link: "account-management",
        },
        {
          name: this.$t("MY_PROFILE.PLANS"),
          link: "subscription-plan",
        },

        {
          name: this.$t("MY_PROFILE.NOTIFICATIONS"),
          link: "notifications",
        },
      ];
      if (this.user && this.user.is_leader) {
        profileTabs.push({
          name: this.$t("MY_PROFILE.FOLLOWERS"),
          link: "followers",
        });
      }

      return profileTabs;
    },

    currentRoute() {
      return this.$route.name;
    },
  },
  methods: {
    ...mapActions(["SetUser", "ShowToast", "SetTypeConfirmation"]),

    handleRedirct(name) {
      this.$router.push({
        name,
      });
    },
    handleOpenExchangeModal() {
      if (!this.user.first_name) {
        this.$bvModal.show("complete-profile-modal");
        return;
      }
      this.SetTypeConfirmation("linkConfirmation");
      this.$bvModal.show("link-exchange-modal");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./index";
</style>
